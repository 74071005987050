<template>
  <div>
    <v-card v-if="generatedImage" max-width="1200px" class="mx-auto my-12" tile color="#FAFAFAFF">
      <v-card-text>
        <v-alert v-if="logosOffscreen.length > 0"
            text
            color="orange darken-2"
            prominent
            icon="mdi-alert"
        >
          <p>{{ $t("logosOffscreenAlert1") }}</p>
          <span v-html="$tc('logosOffscreenAlert2', logosOffscreen.length, {logosOffscreen: logosOffscreen.join(', ')})" />
        </v-alert>
        <v-img :src="generatedImage"></v-img>
      </v-card-text>
      <v-card-actions>
        <v-text-field readonly outlined hide-details background-color="white" class="mr-2" v-model="url" />
        <v-btn
            color="blue-grey"
            class="white--text"
            :loading="generationBeingProcessed"
            @click="copy"
            :x-large="!$vuetify.breakpoint.xs"
        >
          {{ $t('copy') }}
        </v-btn>
        <v-divider vertical class="mx-2" />
        <v-btn
            color="success"
            :loading="generationBeingProcessed"
            @click="download"
            :x-large="!$vuetify.breakpoint.xs"
        >
          {{ $t('download') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <canvas id="myCanvas" :width="canvasWidth" :height="canvasHeight" hidden>
      {{ $t('html5CanvasUnsupportedErrorMsg') }}
    </canvas>
  </div>
</template>
<script>
export default {
  props: {
    skills: {
      type: Array,
      default: () => []
    },
    brandImage: {
      type: String,
      default: () => null
    },
    logoSize: {
      type: Number,
      default: () => 40 * 4
    },
    backgroundColor: {
      type: String,
      default: () => "#FFFFEFFF"
    }
  },
  data: () => ({
    canvasWidth: 1500 * 2,
    canvasHeight: 500 * 2,
    logoMargin: 4 * 4,
    logosAreaWidth: 1500 * 2,
    generatedImage: null,
    generationBeingProcessed: false,
    maxBrandImageWidth: 100 * 4,
    maxBrandImageHeight: 100 * 4,
    logosToDraw: [],
    logosOffscreen: []
  }),
  computed: {
    logoArea() {
      return this.logoSize + 2 * this.logoMargin;
    },
    nbLogoPerRow() {
      return Math.floor(this.logosAreaWidth / this.logoArea);
    },
    nbLogoPerColumn() {
      return Math.floor(this.canvasHeight / this.logoArea);
    },
    url() {
      return "https://bannershake.com?skills=" + this.skills.map(s => s.shortname).join(',')
    }
  },
  methods: {
    generate() {
      this.$emit("in-progress", true);
      this.generationBeingProcessed = true;
      this.logosOffscreen = [];

      // Init canvas
      let canvas = document.createElement("canvas");
      canvas.width = this.canvasWidth;
      canvas.height = this.canvasHeight;

      // Color the canvas
      let ctx = canvas.getContext("2d");
      ctx.fillStyle = this.backgroundColor;
      ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);

      // Append text
      let font = 30;
      ctx.font = font + "px small-caps bold Arial"
      ctx.fillStyle = '#616161FF';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';
      ctx.save();
      let text = "Generated by bannershake.com"
      let xText = font;
      let yText = ctx.measureText(text).width + 120;
      ctx.translate(xText, yText);
      ctx.rotate(-Math.PI / 2);
      ctx.fillText(text, 0, font / 4);
      ctx.restore();

      // Append brand image
      if (this.brandImage) {
        let brand = new Image;
        let _this = this;
        brand.addEventListener("load", function () {
          let width = this.naturalWidth;
          let height = this.naturalHeight;
          if (this.naturalWidth > _this.maxBrandImageWidth) {
            width = _this.maxBrandImageWidth;
            height = width * (this.naturalHeight / this.naturalWidth);
            if (height > (_this.maxBrandImageHeight)) {
              height = _this.maxBrandImageHeight;
              width = height * (this.naturalWidth / this.naturalHeight);
            }
          } else if (this.naturalHeight > _this.maxBrandImageHeight) {
            height = _this.maxBrandImageHeight;
            width = height * (this.naturalWidth / this.naturalHeight);
            if (width > _this.maxBrandImageWidth) {
              width = _this.maxBrandImageWidth;
              height = width * (this.naturalHeight / this.naturalWidth);
            }
          }
          let x = _this.logoSize;
          let y = (_this.canvasHeight / 2) - (height / 2);
          ctx.drawImage(brand, x, y, width, height);

          let xLine = (2 * x) + width;
          let yLine = _this.canvasHeight / 8;
          ctx.beginPath();
          ctx.moveTo(xLine, yLine);
          ctx.lineTo(xLine, 7 * yLine);
          ctx.lineWidth = 4;
          ctx.strokeStyle = '#616161FF';
          ctx.stroke();

          _this.logosAreaWidth = _this.canvasWidth - xLine;

          _this.initLogos(canvas);
        });
        brand.src = this.brandImage;
      } else {
        this.initLogos(canvas);
      }
    },
    initLogos(canvas) {
      let _this = this;
      this.skills.forEach((element, index) => {
        let image = new Image();
        image.addEventListener("load", function () {
          const width = this.naturalWidth;
          const height = this.naturalHeight;
          const scale = height > width / 2 ? 1 : 2
          _this.logosToDraw.splice(index, 0,{
            name: element.name,
            image: image,
            width: width,
            height: height,
            scale: scale
          })
          if (_this.logosToDraw.length === _this.skills.length) {
            _this.drawLogos(canvas)
          }
        });
        image.src = require("../static/" + element.icon);
      });
    },
    drawLogos(canvas) {
      let drawnLogos = 0;
      let ctx = canvas.getContext("2d");
      let nextIndex = 0;
      this.logosToDraw.forEach(element => {
        let height = this.logoSize;
        let width = this.logoSize * (element.width / element.height);
        if (width > this.logoSize * element.scale) {
          width = this.logoSize * element.scale;
          height = this.logoSize * element.scale * (element.height / element.width);
        }
        let yShift = 0;
        if (height < this.logoSize) {
          yShift = (this.logoSize - height) / 2;
        }
        let xShift = 0;
        if (width < this.logoSize) {
          xShift = (this.logoSize - width) / 2;
        }
        let coordinate = this.computeCoordinate(nextIndex, element.scale);
        if (coordinate.offscreen) {
          this.logosOffscreen.push(element.name);
        } else {
          ctx.drawImage(
              element.image,
              coordinate.x + xShift,
              coordinate.y + yShift,
              width,
              height
          );
        }
        drawnLogos++;
        if (drawnLogos === this.skills.length) {
          this.generatedImage = canvas.toDataURL("image/png;base64");
          this.generationBeingProcessed = false;
          this.$emit("in-progress", false);
          this.logosToDraw = [];
        }
        nextIndex = coordinate.nextIndex;
      });
    },
    computeCoordinate(index, scale) {
      let currentIndex = index + scale - 1;
      let remains = currentIndex % this.nbLogoPerRow;
      let shift = 1;
      if (index > 0 && scale > 1 && remains === 0) {
        remains += scale - 1;
        shift++;
      }
      let x = remains + 1;
      let y = Math.floor(currentIndex / this.nbLogoPerRow) + 1;
      return {
        offscreen: y > this.nbLogoPerColumn,
        x: this.canvasWidth - x * this.logoArea,
        y: this.canvasHeight - y * this.logoArea,
        nextIndex: currentIndex + shift
      };
    },
    download() {
      let lnk = document.createElement("a"),
          e;

      /// the key here is to set the download attribute of the a tag
      lnk.download = "skills-banner.png";

      /// convert canvas content to data-uri for link. When download
      /// attribute is set the content pointed to by link will be
      /// pushed as "download" in HTML5 capable browsers
      lnk.href = this.generatedImage;

      /// create a "fake" click-event to trigger the download
      if (document.createEvent) {
        e = document.createEvent("MouseEvents");
        e.initMouseEvent(
            "click",
            true,
            true,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
        );

        lnk.dispatchEvent(e);
      } else if (lnk.fireEvent) {
        lnk.fireEvent("onclick");
      }
    },
    copy() {
      navigator.clipboard.writeText(this.url)
      this.$notify({
        title: this.$t('copyTitle'),
        type: 'success',
        text: this.$t('copyMsg')
      })
    }
  }
}
</script>
